import Container from "../../../Components/Container";
import { Tab } from "@headlessui/react";
import { useEffect, useState } from "react";
import ProductsPanel from "../../../Widgets/ProductsPanel";
import {
  products as productUrl,
} from "../../../Data/apiRoutes";
import { usePaginate } from "../../../Hooks/usePaginate";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default ({ categories, brands, user, token }) => {
  const [allProducts, setAllProducts] = useState([]);
  const [recentProducts, setRecentProducts] = useState([]);

  const {
    currentPage: currentPageAll,
    setTotalPages: setTotalPagesAll,
    PaginateContainer: PaginateContainerAll,
  } = usePaginate({ start: 1 });

  const {
    currentPage: currentPageRecent,
    setTotalPages: setTotalPagesRecent,
    PaginateContainer: PaginateContainerRecent,
  } = usePaginate({ start: 1 });

  const fetchProducts = async (pageNumber = 1, tab = "all") => {
    try {
      const res = await fetch(`${productUrl}?page=${pageNumber}&tab=${tab}`);
      const results = await res.json();
      switch (tab) {
        case "all":
          setAllProducts(results.data);
          setTotalPagesAll(results.pages);
          return;
        case "recent":
          setRecentProducts(results.data);
          setTotalPagesRecent(results.pages);
          return;
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchProducts(currentPageAll);
  }, [currentPageAll]);

  useEffect(() => {
    fetchProducts(currentPageRecent, "recent");
  }, [currentPageRecent]);

  return (
    <Container size="w-full" ypadding="pt-5" xpadding="" transparent padding="">
      {!!allProducts ? (
        <Tab.Group>
          <Tab.List className="border-b border-gray-200 dark:border-gray-700 text-gray-500 dark:text-gray-400">
            {["All", "Recents", "Featured"].map((tab) => (
              <Tab
                className={({ selected }) =>
                  classNames(
                    "outline-none transition-all p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300",
                    selected
                      ? "text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500"
                      : ""
                  )
                }
              >
                {tab}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel>
              <ProductsPanel
                categories={categories}
                brands={brands}
                products={allProducts}
                Pagination={PaginateContainerAll}
              />
            </Tab.Panel>
            <Tab.Panel>
              <ProductsPanel
                categories={categories}
                brands={brands}
                products={recentProducts}
                Pagination={PaginateContainerRecent}
              />
            </Tab.Panel>
            <Tab.Panel>
              <ProductsPanel
                categories={categories}
                brands={brands}
                products={recentProducts}
                Pagination={PaginateContainerRecent}
              />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      ) : (
        <Container className="flex h-[50vh] justify-center items-center">
          <div className="text-xl font-bold">
            There are no products at the moment.
          </div>
        </Container>
      )}
    </Container>
  );
};
