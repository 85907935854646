import MainLayout from "../../Layouts/MainLayout";
import Container from "../../Components/Container";
import {terms} from "../../Data/terms";

export default function Terms() {
    return <MainLayout>
        <Container>
            <div className="font-bold text-3xl text-center">Terms of Service</div>
            <div className="flex justify-center">
                <div className={"dark:bg-white bg-gray-800 my-6 h-1 w-1/12"}/>
            </div>

            {terms.map((term, i) => (
                <div key={`terms-${i}`}>
                    <h1 className="text-2xl font-semibold mt-10 my-4">
                        {term.heading}
                    </h1>
                    <div className={"my-4 text-lg"}>
                        Effective Date: {term.effective_date}
                    </div>
                    {term.content.map((item, j) => (
                        <div key={`term-content-${j}`} className={"my-2"}>
                            <h2 className="text-lg font-semibold">
                                {j+1}. {item.heading}
                            </h2>
                            {item.content.map((item, k) => (
                                <p key={`term-paragraphs-${k}`}>
                                    {item.paragraph}

                                    <ul className={"list-disc list-inside"}>
                                        {item.bullets.map((bullet, index) => (
                                            <li key={`term-bullets-${index}`}>{bullet}</li>
                                        ))}
                                    </ul>
                                </p>
                            ))}
                        </div>
                    ))}
                </div>
            ))}
        </Container>
    </MainLayout>
}