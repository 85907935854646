import {useSelector} from "react-redux";
import MainLayout from "../../Layouts/MainLayout";
import SellerProducts from "./SellerProducts";
import CustomerProducts from "./CustomerProducts";
import {useEffect, useState} from "react";
import {
    products as productUrl,
    categories as categoriesURL, api_url,
} from "../../Data/apiRoutes";

export default function Products() {
    const user = useSelector((state) => state.user);
    const token = useSelector((state) => state.token);
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [brands, setBrands] = useState([]);

    const fetchProducts = async (pageNumber = 1) => {
        try {
            const res = await (user?.role === "seller"
                ? fetch(`${productUrl}/mine`, {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({user_id: user.id}),
                })
                : fetch(`${productUrl}`));
            const data = await res.json();
            setProducts(data.products);
        } catch (error) {
            console.log(error);
        }
    };
    const fetchCategories = async () => {
        try {
            const res = await fetch(categoriesURL);
            const data = await res.json();
            setCategories(data.categories);
        } catch (error) {
            console.log(error);
        }
    };

    const fetchBrands = async () => {
        try {
            const res = await fetch(`${api_url}/brands`);
            const data = await res.json();
            setBrands(data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchProducts(1);
        fetchCategories();
        fetchBrands();
    }, []);

    return (
        <MainLayout>
            {user?.role === "seller" ? (
                <SellerProducts products={products} categories={categories} brands={brands} user={user} token={token}
                                refresh={fetchProducts}/>
            ) : (
                <CustomerProducts categories={categories} brands={brands}  user={user} token={token}/>
            )}
        </MainLayout>
    );
}
