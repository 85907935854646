import axios from "axios";
import {api_url} from "../Data/apiRoutes";
import {toast} from "react-toastify";


export const fetchCategories = async (set) => {
    try {
        const response = await axios.get(`${api_url}/categories`)
        set(response.data.categories)
    } catch (e) {
        toast.error(e.message);
    }
}