import Container from "../../Components/Container";
import MainLayout from "../../Layouts/MainLayout";
import { GiBullseye } from "react-icons/gi";
import { FaEye } from "react-icons/fa";
import { SocialIcon } from "react-social-icons";
import { useSelector } from "react-redux";
import Seller from "./Seller";
import Customer from "./Customer";

export default () => {
  const user = useSelector((state) => state.user);

  return user?.role === "seller" ? <Seller /> : <Customer />;
};
