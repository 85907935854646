import TextArea from "../../../Components/TextArea";
import InputError from "../../../Components/InputError";
import InputLabel from "../../../Components/InputLabel";
import TextInput from "../../../Components/TextInput";
import Modal from "../../../Components/Modal";
import { useState, useRef, useEffect } from "react";
import PrimaryButton from "../../../Components/PrimaryButton";
import { api_url } from "../../../Data/apiRoutes";

export default ({
  token = null,
  show = false,
  close = () => {},
  category,
  refresh = () => {},
}) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const imageRef = useRef(null);

  const [data, setData] = useState({
    name: "",
    picture: null,
    description: category.description,
  });

  const [errors, setErrors] = useState({
    name: "",
    picture: null,
    description: "",
  });

  useEffect(() => {
    setData({
      name: category.name,
      picture: null,
      description: category.description,
    });
  }, [category]);

  const selectImage = () => {
    imageRef.current.click();
  };

  const onChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setData({ ...data, [e.target.name]: e.target.files[0] });
      if (e.target.name == "picture") {
        setSelectedImage(e.target.files[0]);
      }
      return;
    }
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const action = async () => {
    setIsProcessing((s) => !s);
    try {
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("description", data.description);
      formData.append("photo", selectedImage);

      const response = await fetch( `${api_url}/brands/${category.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "PATCH",
        body: formData,
      });
      console.log(await response.json());
      setData({
        name: "",
        picture: null,
        description: "",
      });
      setSelectedImage(null);
      refresh();
      close();
    } catch (err) {
      console.error(err);
    }
    setIsProcessing((s) => !s);
  };

  return (
    <Modal
      maxWidth="max-w-lg w-full"
      title="Update Brand"
      show={show}
      close={close}
      customOk={
        <PrimaryButton disabled={isProcessing} onClick={action}>
          Save
        </PrimaryButton>
      }
    >
      <div className="md:grid grid-cols-2 gap-4">
        <div className="flex justify-center col-span-2">
          <div className="w-[150px] h-[150px] relative">
            <TextInput
              id="picture"
              name="picture"
              type="file"
              accept="image/*"
              className="hidden"
              ref={imageRef}
              onChange={onChange}
            />
            <img
              src={
                selectedImage
                  ? URL.createObjectURL(selectedImage)
                  : category?.picture
                  ? `${
                      process.env.REACT_APP_BACKEND_URL
                    }/${category?.picture.replace(/"/g, "")}`
                  : "/img/image_icon1.png"
              }
              className="object-cover h-full"
              alt="shop"
            />
            <div
              onClick={selectImage}
              className="cursor-pointer absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-[hsl(0,0%,98.4%,0.2)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100 flex justify-center items-center"
            >
              <div>Select Image</div>
            </div>

            <div
              className="cursor-pointer absolute z-40 bottom-1 right-1 bg-blue-500 p-1 rounded"
              onClick={selectImage}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="fill-white"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="m18.988 2.012 3 3L19.701 7.3l-3-3zM8 16h3l7.287-7.287-3-3L8 13z"></path>
                <path d="M19 19H8.158c-.026 0-.053.01-.079.01-.033 0-.066-.009-.1-.01H5V5h6.847l2-2H5c-1.103 0-2 .896-2 2v14c0 1.104.897 2 2 2h14a2 2 0 0 0 2-2v-8.668l-2 2V19z"></path>
              </svg>
            </div>
          </div>
        </div>
        <div className="col-span-2">
          <InputLabel htmlFor="name" value="Brand Name *" />

          <TextInput
            id="name"
            name="name"
            className="mt-1 block w-full"
            value={data.name}
            onChange={onChange}
          />

          <InputError message={errors?.name} className="mt-2 capitalize" />
        </div>

        <div className="col-span-2">
          <InputLabel htmlFor="description" value="Brand Description" />

          <TextArea
            id="description"
            name="description"
            className="mt-1 block w-full"
            value={data.description}
            onChange={onChange}
          />

          <InputError
            message={errors?.description}
            className="mt-2 capitalize"
          />
        </div>
      </div>
    </Modal>
  );
};
