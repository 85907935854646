import { useSelector } from "react-redux";
import MainLayout from "../../Layouts/MainLayout";
import AdminBrands from "./Admin/AdminBrands";

export default function Brands() {
  const user = useSelector((state) => state.user);
  return (
    <MainLayout>
      {user?.role === "admin" ? <AdminBrands user={user} /> : <></>}
    </MainLayout>
  );
}
