import { useSelector } from "react-redux";
import MainLayout from "../../Layouts/MainLayout";
import { useParams } from "react-router-dom";
import SellerShowProduct from "./SellerShowProduct";
import { useEffect, useState } from "react";
import {products as productsUrl, categories as categoriesURL, api_url,} from "../../Data/apiRoutes";
import CustomerShowProduct from "./CustomerShowProduct";

export default function ProductShow() {
  const { id } = useParams();
  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);
  const [product, setProduct] = useState({});
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [images, setImages] = useState({});

  const fetchProduct = async () => {
    const res = await fetch(productsUrl + `/${id}`);
    const { product } = await res.json();
    setProduct(product);

    const imgs = [];

    imgs.push({
      original: `${process.env.REACT_APP_BACKEND_URL}/${product.picture
        .replace('"', "")
        .replace('"', "")}`,
      thumbnail: `${process.env.REACT_APP_BACKEND_URL}/${product.picture
        .replace('"', "")
        .replace('"', "")}`,
    });

    product.ProductVariants.forEach((variant) => {
      imgs.push({
        original: `${process.env.REACT_APP_BACKEND_URL}/${variant.picture
          .replace('"', "")
          .replace('"', "")}`,
        thumbnail: `${process.env.REACT_APP_BACKEND_URL}/${variant.picture
          .replace('"', "")
          .replace('"', "")}`,
      });
    });
    setImages(imgs);
  };

  const fetchCategories = async () => {
    try {
      const res = await fetch(categoriesURL);
      const data = await res.json();
      setCategories(data.categories);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchBrands = async () => {
    try {
      const res = await fetch(`${api_url}/brands`);
      const data = await res.json();
      setBrands(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchProduct();
    fetchCategories();
    fetchBrands();
  }, [id]);
  return (
    <MainLayout>
      {user?.role === "seller" ? (
        <SellerShowProduct
          product={product}
          setProduct={setProduct}
          categories={categories}
          brands={brands}
          images={images}
          token={token}
          user={user}
          refresh={fetchProduct}
        />
      ) : (
        <CustomerShowProduct user={user} product={product} images={images} />
      )}
    </MainLayout>
  );
}
