import Modal from "../../../Components/Modal";
import InputLabel from "../../../Components/InputLabel";
import TextInput from "../../../Components/TextInput";
import {useState, useRef} from "react";
import TextArea from "../../../Components/TextArea";
import Select from "../../../Components/Select";
import {toast} from "react-toastify";
import axios from "axios";

export default function NewAd(props) {
    const {refresh, show, close, token, user, products = []} =
        props;
    const imageRef = useRef(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [data, setData] = useState({
        picture: undefined,
        name: "",
        price: 0.0,
        description: "",
    });

    const selectImage = () => {
        imageRef.current.click();
    };

    const onChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setData({...data, [e.target.name]: e.target.files[0]});
            setSelectedImage(e.target.files[0]);
            return;
        }
        setData({...data, [e.target.name]: e.target.value});
    };

    const nextPage = async () => {
        if (
            data.picture &&
            data.name &&
            data.start_date &&
            data.price &&
            data.period
        ) {
            const end_date = new Date(data.start_date);
            switch (data.period.toLowerCase()) {
                case "a week":
                    end_date.setDate((new Date(data.start_date)).getDate() + 7);
                    break;
                case "two weeks":
                    end_date.setDate((new Date(data.start_date)).getDate() + 14);
                    break;
                case "three weeks":
                    end_date.setDate((new Date(data.start_date)).getDate() + 21);
                    break;
                default:
                    end_date.setDate((new Date(data.start_date)).getDate() + 30);
                    break;
            }

            const formData = new FormData();
            formData.append('title', data.name);
            formData.append('description', data.description);
            formData.append('photo', data.picture);
            formData.append('start_date', data.start_date);
            formData.append('end_date', end_date.toISOString().split('T')[0]);
            formData.append('price', data.price);
            formData.append('user_id', user.id);

            if(data.target_max_age) formData.append('target_max_age', data.target_max_age);
            if(data.target_min_age) formData.append('target_min_age', data.target_min_age);
            if(data.ad_type) formData.append('ad_type', data.ad_type);
            if(data.product) formData.append('product_id', data.product.split(' - ')[0]);

            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/adverts`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            refresh()
            close();
        } else {
            toast.warning(
                "All non-optional fields are required!"
            );
        }
    };

    return (
        <Modal
            show={show}
            title="New Ad"
            close={close}
            okAction={nextPage}
            maxWidth="max-w-3xl"
        >
            <div className="sm:grid grid-cols-4 gap-2">
                <div className="flex justify-center col-span-4">
                    <div className="w-[320px] md:w-[720px] h-[156px] md:h-[350px] relative overflow-hidden">
                        <TextInput
                            id="picture"
                            name="picture"
                            type="file"
                            accept="image/jpeg,image/jpg,image/png"
                            className="hidden"
                            ref={imageRef}
                            onChange={onChange}
                        />
                        <img
                            src={
                                selectedImage
                                    ? URL.createObjectURL(selectedImage)
                                    : "/img/image_icon1.png"
                            }
                            className="w-[320px] md:w-[720px] h-[156px] md:h-[350px] object-cover"
                            alt="shop photo"
                        />
                        <div
                            onClick={selectImage}
                            className="cursor-pointer absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-[hsl(0,0%,98.4%,0.2)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100 flex justify-center items-center"
                        >
                            <div>Select Image</div>
                        </div>

                        <div
                            className="cursor-pointer absolute z-40 bottom-1 right-1 bg-blue-500 p-1 rounded"
                            onClick={selectImage}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="fill-white"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                            >
                                <path d="m18.988 2.012 3 3L19.701 7.3l-3-3zM8 16h3l7.287-7.287-3-3L8 13z"></path>
                                <path
                                    d="M19 19H8.158c-.026 0-.053.01-.079.01-.033 0-.066-.009-.1-.01H5V5h6.847l2-2H5c-1.103 0-2 .896-2 2v14c0 1.104.897 2 2 2h14a2 2 0 0 0 2-2v-8.668l-2 2V19z"></path>
                            </svg>
                        </div>
                    </div>
                </div>

                <div className="sm:grid grid-cols-9 col-span-4 gap-2">
                    <div className="col-span-9">
                        <InputLabel htmlFor="name" value="Title *"/>

                        <TextInput
                            id="name"
                            name="name"
                            className="mt-1 block w-full"
                            value={data.name}
                            onChange={onChange}
                        />
                    </div>

                    <div className="col-span-3">
                        <InputLabel htmlFor="price" value="Price *"/>

                        <TextInput
                            id="price"
                            name="price"
                            type={'number'}
                            step="0.01"
                            className="mt-1 block w-full"
                            value={data.price}
                            onChange={onChange}
                        />
                    </div>

                    <div className="col-span-3">
                        <InputLabel htmlFor="start_date" value="Start Date *"/>

                        <TextInput
                            id="start_date"
                            name="start_date"
                            type={'date'}
                            className="mt-1 block w-full"
                            value={data.start_date}
                            onChange={onChange}
                        />
                    </div>

                    <div className="col-span-3">
                        <InputLabel htmlFor="period" value="Period"/>

                        <Select
                            id="period"
                            name="period"
                            className="mt-1 block w-full"
                            value={data.period}
                            onChange={onChange}
                            options={['A Week', 'Two Weeks', 'Three Weeks', 'A Month']}
                        />
                    </div>
                </div>

                <div className="col-span-4">
                    <InputLabel htmlFor="description" value="Description"/>

                    <TextArea
                        id="description"
                        name="description"
                        className="mt-1 block w-full"
                        value={data.description}
                        onChange={onChange}
                    />
                </div>

                <div className="sm:grid grid-cols-2 col-span-4 gap-2">
                    <div className="col-span-2 border-b text-black dark:text-white">
                        Optional Fields
                    </div>

                    <div className="">
                        <InputLabel htmlFor="target_min_age" value="Target Minimum Age"/>

                        <TextInput
                            id="target_min_age"
                            name="target_min_age"
                            className="mt-1 block w-full"
                            value={data.target_min_age}
                            onChange={onChange}
                        />
                    </div>

                    <div className="">
                        <InputLabel htmlFor="target_max_age" value="Target Maximum Age"/>

                        <TextInput
                            id="target_max_age"
                            name="target_max_age"
                            className="mt-1 block w-full"
                            value={data.target_max_age}
                            onChange={onChange}
                        />
                    </div>

                    <div className="">
                        <InputLabel htmlFor="ad_type" value="Ad Type"/>

                        <Select
                            id="ad_type"
                            name="ad_type"
                            className="mt-1 block w-full"
                            value={data.ad_type}
                            onChange={onChange}
                            options={['banner', 'popup', 'sponsored']}
                        />
                    </div>

                    <div className="">
                        <InputLabel htmlFor="product" value="Existing Product"/>

                        <Select
                            id="product"
                            name="product"
                            className="mt-1 block w-full"
                            value={data.product}
                            onChange={onChange}
                            options={products?.length > 0 ? products?.map(p => p.id + ' - ' + p.name) : []}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
};
