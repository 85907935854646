import axios from "axios";
import {api_url} from "../Data/apiRoutes";
import {toast} from "react-toastify";


export const fetchBrands = async (set) => {
    try {
        const response = await axios.get(`${api_url}/brands`)
        set(response.data)
    } catch (e) {
        toast.error(e.message);
    }
}