import Jumbotron from "./Jumbotron";
import ProductsWidget from "../../../Widgets/HomeProductsWidget";
import { BiSolidCategory } from "react-icons/bi";
import {
    useTopDiscounts,
    useBestSeller,
    useFeaturedProducts,
    useExploreProducts,
} from "../../../Hooks/useProducts";
import Adverts from "./Adverts";
import AdvertCarousel from "./AdvertCarousel";
import {toast} from "react-toastify";
import axios from "axios";
import {api_url} from "../../../Data/apiRoutes";
import {useEffect, useState} from "react";
import Container from "../../../Components/Container";
import {fetchCategories} from "../../../Services/categories";
import {fetchBrands} from "../../../Services/brands";
import {Link} from "react-router-dom";

export default function CustomerHome() {
    const topDiscounts = useTopDiscounts();
    const bestSeller = useBestSeller();
    const featuredProducts = useFeaturedProducts();
    const exploreProducts = useExploreProducts();

    const [adverts, setAdverts] = useState([]);

    const [categories, setCategories] = useState([]);
    const [brands, setBrands] = useState([]);

    const fetchAds = async () => {
        try {
            const response = await axios.get(`${api_url}/adverts?limit=10`,)
            setAdverts(response.data)
        } catch (e) {
            toast.error(e.message);
        }
    }

    useEffect(() => {
        fetchAds()
        fetchCategories(setCategories)
        fetchBrands(setBrands)
    }, []);

    return (
        <>
            <Jumbotron/>

            {categories?.length > 0 && <Container transparent xpadding={''}>
                <div className="mb-4 font-semibold text-lg lg:text-xl">
                    Shop by Category
                </div>
                <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-2">
                    {categories?.map((category, index) => (
                        <div
                            key={index}
                            className="flex flex-col gap-3 items-center p-2 rounded hover:scale-[1.01] bg-white dark:bg-gray-800  transition-all shadow-sm"
                        >
                            <Link to={"/products"} className="h-48 w-full mb-3 cursor-pointer">
                                <img
                                    src={category?.picture
                                        ? `${process.env.REACT_APP_BACKEND_URL}/${category?.picture
                                            ?.replaceAll('"', "")}`
                                        : "/img/categories.png"}
                                    alt="category"
                                    className="h-48 w-full object-contain bg-white"
                                />
                            </Link>
                            <Link to={"/products"} className="capitalize text-center cursor-pointer">{category?.name}</Link>
                        </div>
                    ))}
                </div>
            </Container>}

            {brands?.length > 0 && <Container transparent xpadding={''}>
                <div className="mb-4 font-semibold text-lg lg:text-xl">
                    Shop by Brand
                </div>
                <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-2">
                    {brands?.map((brand, index) => (
                        <div
                            key={index}
                            className="flex flex-col gap-3 items-center p-2 rounded hover:scale-[1.01] bg-white dark:bg-gray-800  transition-all shadow-sm"
                        >
                            <Link to={"/products"} className="h-48 w-full mb-3 cursor-pointer">
                                <img
                                    src={brand?.picture
                                        ? `${process.env.REACT_APP_BACKEND_URL}/${brand?.picture
                                            ?.replaceAll('"', "")}`
                                        : "/img/categories.png"}
                                    alt="brand"
                                    className="h-48 w-full object-cover bg-white"
                                />
                            </Link>
                            <Link to={"/products"} className="capitalize cursor-pointer">{brand?.name}</Link>
                        </div>
                    ))}
                </div>
            </Container>}

            {!!exploreProducts?.data && exploreProducts?.data?.products?.length > 0 && (
                <ProductsWidget
                    title={
                        <>
                            <div className="font-black text-3xl">Explore</div>
                            {/* <div className="text-normal text-xs md:text-sm">Products</div> */}
                        </>
                    }
                    products={exploreProducts.data?.data}
                />
            )}

            {!!bestSeller?.data && bestSeller?.data?.products?.length > 0 && (
                <ProductsWidget
                    title={
                        <>
                            <div className="font-black text-3xl">Best Seller</div>
                            <div className="text-normal text-xs md:text-sm">Products</div>
                        </>
                    }
                    products={bestSeller.data?.products}
                />
            )}
            <Adverts ads={adverts?.length > 0 ? adverts.filter((ad, i) => i < 4) : null}/>
            {!!topDiscounts?.data && topDiscounts?.data?.products?.length > 0 && (
                <ProductsWidget
                    title={
                        <>
                            <div className="font-black text-3xl">This Month's</div>
                            <div className="text-normal text-xs md:text-sm">Deals</div>
                        </>
                    }
                    products={topDiscounts.data?.products}
                />
            )}

            <AdvertCarousel ads={adverts?.length > 0 ? adverts.filter((ad, i) => i >= 4) : null}/>

            {!!featuredProducts?.data && featuredProducts?.data?.products?.length > 0 && (
                <ProductsWidget
                    title={
                        <>
                            <div className="font-black text-3xl">Featured</div>
                            <div className="text-normal text-xs md:text-sm">Products</div>
                        </>
                    }
                    products={featuredProducts.data?.products}
                />
            )}
        </>
    );
}
