import Container from "../../../Components/Container";
import MainLayout from "../../../Layouts/MainLayout";
import { GiBullseye } from "react-icons/gi";
import { FaEye } from "react-icons/fa";
import { SocialIcon } from "react-social-icons";

export default () => {
  return (
    <MainLayout>
      <section className="">
        <div className="py-6 mt-10 md:mt-12 mx-auto text-center lg:py-10 relative">
          <h1 className="mb-8 text-4xl font-black tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl dark:text-white">
            ABOUT US
          </h1>
          <p className="text-lg font-normal text-gray-500 lg:text-xl sm:px-16 lg:px-48 dark:text-gray-200">
            Who we are.
          </p>
        </div>

        {/* <div
          className="bg-fixed w-full absolute top-0 left-0 h-full bg-cover"
          style={{
            backgroundImage: "url('/img/shopping-bg.jpg')",
            zIndex: "-2",
          }}
        ></div> */}

        <div
          className="bg-gradient-to-b from-blue-100 to-transparent dark:from-blue-950 w-full h-full absolute top-0 left-0"
          style={{
            zIndex: "-1",
          }}
        ></div>
      </section>

      <Container transparent className="text-center space-y-4">
        <div>Malonda265 is a platform for national wholesale trade.</div>
        <div>
          We intend to serve millions of buyers and suppliers national wide.
        </div>
      </Container>

      <Container
        ypadding=""
        xpadding=""
        padding=""
        transparent
        className="md:flex items-stretch "
      >
        <Container>
          <div className="text-xl font-bold border-b flex gap-3">
            <GiBullseye className="text-3xl" /> Our Mission
          </div>
          <div className="mt-3 text-justify">
            As part of Malonda265 Malawi, our mission is to make it easy to do
            business anywhere anytime.
          </div>
          <div className="mt-3 text-justify">
            We do this by giving suppliers the tools necessary to reach a
            national audience for their products, and by helping buyers find
            products and suppliers quickly and efficiently.
          </div>
        </Container>

        <Container>
          <div className="text-xl font-bold border-b flex gap-3">
            <FaEye className="text-3xl" /> Vision
          </div>
          <div className="mt-3 text-justify">
            As a platform, we continue to develop services to help businesses do
            more and discover new markets and opportunities.
          </div>
          <div className="mt-3 text-justify">
            Whether it's sourcing from your mobile phone or contacting suppliers
            from kilometres away, turn to Malonda265 for all your global
            business needs.
          </div>
        </Container>
      </Container>

      <Container
        size="w-fit"
        className="text-xl font-bold"
        ypadding=""
        transparent
      >
        Our Team
      </Container>

      <Container
        transparent
        ypadding=""
        xpadding=""
        padding=""
        className="md:grid grid-cols-4 place-items-center justify-center m-auto"
      >
        <div></div>
        <Container className="flex flex-col justify-center items-center">
          <img src="/img/user-icon.png" className="w-52 h-52 object-cover" />
          <div className="text-xl font-bold">Emmanuel Kathindwa</div>
          <div className="text-sm text-gray-500">
            Co-Founder & Executive Director
          </div>
          <div className="flex gap-1 sm:gap-2">
            <SocialIcon
              url="https://linkedin.com/"
              style={{
                width: "40px",
                height: "40px",
              }}
            />

            <SocialIcon
              url="mailto:emmanuelkathindwa@yahoo.com"
              network="email"
              style={{
                width: "40px",
                height: "40px",
              }}
            />

            <SocialIcon
              url="https://instagram.com/"
              style={{
                width: "40px",
                height: "40px",
              }}
            />

            <SocialIcon
              url="https://wa.me/"
              network="whatsapp"
              style={{
                width: "40px",
                height: "40px",
              }}
            />
          </div>
        </Container>
        <Container className="flex flex-col justify-center items-center">
          <img src="/img/user-icon.png" className="w-52 h-52 object-cover" />
          <div className="text-xl font-bold">Happy Lakudzala</div>
          <div className="text-sm text-gray-500">
            Co-Founder & Lead Developer
          </div>
          <div className="flex gap-1 sm:gap-2">
            <SocialIcon
              url="https://linkedin.com/in/happy.lakudzala"
              style={{
                width: "40px",
                height: "40px",
              }}
            />

            <SocialIcon
              url="mailto:hlakudzala@gmail.com"
              network="email"
              style={{
                width: "40px",
                height: "40px",
              }}
            />

            <SocialIcon
              url="https://github.com/"
              style={{
                width: "40px",
                height: "40px",
              }}
            />

            <SocialIcon
              url="https://wa.me/"
              network="whatsapp"
              style={{
                width: "40px",
                height: "40px",
              }}
            />
          </div>
        </Container>
      </Container>
    </MainLayout>
  );
};
