import AuthLayout from "../../Layouts/AuthLayout";
import MainLayout from "../../Layouts/MainLayout";
import TextInput from "../../Components/TextInput";
import InputLabel from "../../Components/InputLabel";
import InputError from "../../Components/InputError";
import PrimaryButton from "../../Components/PrimaryButton";
import SecondaryButton from "../../Components/SecondaryButton";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import GoogleIcon from "../../Assets/JSX/GoogleIcon";
import FacebookIcon from "../../Assets/JSX/FacebookIcon";
import GithubIcon from "../../Assets/JSX/GithubIcon";
import { oauth } from "../../Functions/oauth";
import { useDispatch } from "react-redux";
import { setLogin } from "../../Context/reducer";
import Spin from "../../Assets/JSX/Spin";
import { login } from "../../Data/apiRoutes";
import { useQuery } from "../../Hooks/useQuery";

export default function Login() {
  const dispatch = useDispatch();

  const [data, setData] = useState({
    username: "",
    password: "",
  });
  // eslint-disable-next-line
  const [errors, setErrors] = useState({
    username: "",
    password: "",
  });
  const [processing, setProcessing] = useState(false);

  const handleOnChange = (e) =>
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });

  const thirdPartyAuth = async () => {
    setProcessing((s) => !s);
    oauth("google");
  };

  const submit = async (e) => {
    e.preventDefault();
    setErrors({
      username: "",
      password: "",
    });
    setProcessing((s) => !s);
    try {
      if (!!data.username && !!data.password) {
        const response = await fetch(login, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });

        if (response.status === 200) {
          const output = await response.json();
          console.log(output);
          dispatch(setLogin(output));
        } else if (response.status === 401) {
          setErrors({
            username: "",
            password: "Incorrect Username/Password",
          });
        } else {
          setErrors({
            username: "Failed to Login",
            password: "Incorrect Username/Password",
          });
        }
      } else {
        setErrors({
          username: !!data.username ? "" : "Username field is required!",
          password: !!data.password ? "" : "Password field is required!",
        });
      }
    } catch (error) {
      console.log(error);
      setErrors({
        username: "",
        password: "Failed to Fetch",
      });
    }
    setProcessing((s) => !s);
  };

  return (
    <MainLayout top="">
      <AuthLayout title="Sign In">
        <form onSubmit={submit}>
          <div className="my-3">
            <InputLabel htmlFor="username" value="Username" />

            <TextInput
              id="username"
              type="text"
              name="username"
              value={data.username}
              className="mt-1 block w-full"
              autoComplete="username"
              onChange={handleOnChange}
            />

            <InputError message={errors.username} className="mt-2" />
          </div>
          <div className="my-3 flex flex-col">
            <TextInput
              id="password"
              type="password"
              name="password"
              value={data.password}
              className="mt-1 block w-full"
              autoComplete="username"
              onChange={handleOnChange}
            />

            <div className="flex justify-between order-first">
              <InputLabel htmlFor="password" value="Password" />
              <Link
                to="/forgot-password"
                className="block font-medium text-gray-700 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400"
              >
                Forgot Password
              </Link>
            </div>

            <InputError message={errors.password} className="mt-2" />
          </div>

          <div className="flex items-center justify-end my-6">
            <Link
              to={"/register"}
              className="underline text-sm text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-100 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:focus:ring-offset-gray-800"
            >
              Sign Up instead?
            </Link>

            <PrimaryButton className="ml-4" disabled={processing}>
              {processing && <Spin />} Log in
            </PrimaryButton>
          </div>
        </form>

        <div className="hidden">
          <div className="flex justify-between items-center my-6">
            <hr className="w-[40%]" />
            <div className="font-bold text-sm">OR</div>
            <hr className="w-[40%]" />
          </div>
          <div className="flex flex-col gap-3 justify-around mt-3">
            <SecondaryButton
              onClick={thirdPartyAuth}
              className="flex items-center justify-center gap-3"
              disabled={processing}
            >
              {processing && <Spin />}
              <GoogleIcon className="dark:fill-white fill-black" />
              <div>Sign in with Google</div>
            </SecondaryButton>
            {/* <PrimaryButton className="flex items-center justify-center gap-3">
            <FacebookIcon className="fill-white dark:fill-black" />
            <div>Sign in with Facebook</div>
          </PrimaryButton>
          <PrimaryButton className="flex items-center justify-center gap-3">
            <GithubIcon className="fill-white dark:fill-black" />
            <div>Sign in with Github</div>
          </PrimaryButton> */}
          </div>
        </div>
      </AuthLayout>
    </MainLayout>
  );
}
