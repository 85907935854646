export const terms = [
    {
        heading: "Terms and Conditions (T&Cs)",
        effective_date: "15th March 2025",
        content: [
            {
                heading: "Introduction",
                content: [
                    {
                        paragraph: "Welcome to Malonda265. By accessing or using our platform, you agree to be bound by these Terms and Conditions. Please read them carefully.",
                        bullets: []
                    },
                ],
            },
            {
                heading: "Platform Usage",
                content: [
                    {
                        paragraph: "Malonda265 is an online marketplace connecting buyers with automotive sellers in Malawi and beyond. We do not own\n" +
                            "or sell the products listed by third-party sellers.",
                        bullets: []
                    },
                ],
            },
            {
                heading: "User Responsibilities",
                content: [
                    {
                        paragraph: "",
                        bullets: [
                            "Provide accurate personal and business information",
                            "Use the platform for legal and ethical purposes only",
                            "Refrain from engaging in fraudulent activity or misleading listings",
                        ]
                    },
                ],
            },
            {
                heading: "Orders & Payments",
                content: [
                    {
                        paragraph: "Where direct payment is enabled, Malonda265 may process payments on behalf of sellers. Otherwise, users transact\n" +
                            "externally and are responsible for confirming details with the seller.",
                        bullets: []
                    },
                ],
            },
            {
                heading: "Disputes",
                content: [
                    {
                        paragraph: "Malonda265 is not responsible for disputes between users and sellers, but we may intervene where necessary to\n" +
                            "maintain platform integrity.",
                        bullets: []
                    },
                ],
            },
            {
                heading: "Account Termination",
                content: [
                    {
                        paragraph: "We reserve the right to suspend or terminate accounts that breach these terms or harm the platform.",
                        bullets: []
                    },
                ],
            },
            {
                heading: "Changes to Terms",
                content: [
                    {
                        paragraph: "We may update these terms at any time. Continued use of the platform indicates acceptance of any changes.",
                        bullets: []
                    },
                ],
            },
        ]
    },
    {
        heading: "Privacy Policy",
        effective_date: "15th March 2025",
        content: [
            {
                heading: "Information We Collect",
                content: [
                    {
                        paragraph: "",
                        bullets: [
                            "Personal details (name, contact, location, date of birth, gender)",
                            "Business details for sellers",
                            "Usage data (browsing activity, IP address)"
                        ]
                    },
                ],
            },
            {
                heading: "How We Use Your Information",
                content: [
                    {
                        paragraph: "",
                        bullets: [
                            "To provide and improve our platform",
                            "To verify sellers and prevent fraud",
                            "To communicate updates, marketing, and support",
                        ]
                    },
                ],
            },
            {
                heading: "Data Sharing",
                content: [
                    {
                        paragraph: "We do not sell your data. Information may be shared with trusted third parties for essential services (e.g. hosting, payments, analytics) or when required by law.",
                        bullets: []
                    },
                ],
            },
            {
                heading: "Your Rights",
                content: [
                    {
                        paragraph: "You can access, update, or request deletion of your data by contacting us at Malonda265.mw@gmail.com",
                        bullets: []
                    },
                ],
            },
            {
                heading: "Security",
                content: [
                    {
                        paragraph: "We implement reasonable security measures but cannot guarantee complete security.",
                        bullets: []
                    },
                ],
            },
        ],
    },
    {
        heading: "Seller Agreement",
        effective_date: "15th March 2025",
        content: [
            {
                heading: "Seller Eligibility",
                content: [
                    {
                        paragraph: "Sellers must provide verifiable business information and agree to abide by Malonda265 policies.",
                        bullets: []
                    },
                ],
            },
            {
                heading: "Listing Requirements",
                content: [
                    {
                        paragraph: "",
                        bullets: [
                            "Only genuine, available products/services may be listed",
                            "Sellers must update listings regularly",
                            "Misleading listings will result in suspension",
                        ]
                    },
                ],
            },
            {
                heading: "Fees & Payments",
                content: [
                    {
                        paragraph: "",
                        bullets: [
                            "Free 30-day trial period",
                            "After the trial, tiered pricing will apply (Basic, Pro, Premium)",
                            "Where applicable, Malonda265 may retain a service fee for transactions",
                        ]
                    },
                ],
            },
            {
                heading: "Communication",
                content: [
                    {
                        paragraph: "Sellers are expected to respond to inquiries promptly and professionally.",
                        bullets: []
                    },
                ],
            },
            {
                heading: "Liability",
                content: [
                    {
                        paragraph: "Malonda265 is not liable for losses arising from seller-buyer transactions. Sellers are responsible for the accuracy of\n" +
                            "their listings and fulfillment of orders.",
                        bullets: []
                    },
                ],
            },
            {
                heading: "Termination",
                content: [
                    {
                        paragraph: "We may suspend or remove sellers who breach this agreement or harm the platform's reputation.",
                        bullets: []
                    },
                ],
            },
        ],
    },
]