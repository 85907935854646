export const customer = [
  { text: "Home", href: "/" },
  { text: "Shops", href: "/shops" },
  { text: "Products", href: "/products" },
  { text: "Messages", href: "/messages" },
  { text: <div>About&nbsp;Us</div>, href: "/about-us" },
];

export const seller = [
  { text: "Home", href: "/" },
  { text: "Shops", href: "/shops" },
  { text: "Products", href: "/products" },
  { text: "Advertise", href: "/advertise" },
  { text: "Messages", href: "/messages" },
  { text: "Pricing", href: "/pricing" },
  { text: <div>About&nbsp;Us</div>, href: "/about-us" },
];

export const admin = [
  { text: "Dashboard", href: "/" },
  { text: "Categories", href: "/categories" },
  { text: "Brands", href: "/brands" },
  { text: "Shops", href: "/shops" },
  { text: "Users", href: "/users" },
  { text: "Subscriptions", href: "/subscriptions" },
];

export const guest = [
  { text: "Pricing", href: "/pricing" },
  { text: <div>Sign&nbsp;In</div>, href: "/login" },
];
