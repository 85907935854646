import { Link } from "react-router-dom";
import Search from "./Search";
import { useEffect, useState } from "react";

export default function Jumbotron() {
  const [imageIndex, setImageIndex] = useState(0);
  const images = [
    "bas-peperzak-_bNIvRUvjOk-unsplash.jpg",
    "koons-automotive-VLet0OidKT8-unsplash.jpg",
    "vitali-adutskevich-JTH1AUX7W5U-unsplash.jpg",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [images.length]);
  return (
    <section className="h-[80vh] md:h-screen flex">
      <div className="py-8 mt-20 md:mt-32 mx-auto text-center lg:py-16 relative">
        <Link
          to="/products"
          className="inline-flex justify-between items-center py-1 px-1 pr-4 mb-7 text-sm text-blue-700 bg-blue-100 rounded-full dark:bg-blue-900 dark:text-blue-300 hover:bg-blue-200 dark:hover:bg-blue-800"
        >
          <span className="text-xs bg-blue-600 rounded-full text-white px-4 py-1.5 mr-3">
            Explore
          </span>
          <span className="text-sm font-medium">See what's new!</span>
          <svg
            className="w-2.5 h-2.5 ml-2"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 6 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 9 4-4-4-4"
            />
          </svg>
        </Link>
        <h1 className="mb-8 text-4xl font-black tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl dark:text-white">
          World of Endless Possibilities!
        </h1>
        <p className="mb-10 md:mb-24 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 lg:px-48 dark:text-gray-200">
          Discover Your Desires, Shop Your Dreams. Unleash the Joy of Shopping
          with Us!
        </p>
        <div className="mt-12 md:mt-0">
          <Search />
        </div>
      </div>

      <div
        className="w-full absolute top-0 left-0 h-[80vh] md:h-screen bg-gray-900 opacity-50"
        style={{
          zIndex: "-1",
        }}
      ></div>
      <div
        className="bg-fixed w-full absolute top-0 left-0 bg-cover bg-center h-[80vh] md:h-screen transition-all duration-1000"
        style={{
          backgroundImage: `url('/img/${images[imageIndex]}')`,
          zIndex: "-2",
        }}
      ></div>
      <div
        className="bg-gradient-to-b from-blue-100 to-transparent dark:from-blue-950 w-full h-full absolute top-0 left-0 opacity-75"
        style={{
          zIndex: "-1",
        }}
      ></div>
    </section>
  );
}
