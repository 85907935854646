import Container from "../../../Components/Container";
import MainLayout from "../../../Layouts/MainLayout";
import PrimaryButton from "../../../Components/PrimaryButton";
import NewAd from "./NewAd";
import {api_url, products as productUrl} from "../../../Data/apiRoutes";
import axios from "axios";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import Dropdown from "../../../Components/DropDown";
import {FaGear} from "react-icons/fa6";
import {dateFormat, numberFormat} from "../../../Functions/functions";

export default () => {
    const user = useSelector(state => state.user)
    const token = useSelector(state => state.token)
    const [products, setProducts] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [ads, setAds] = useState([]);

    const fetchProducts = async () => {
        try {
            const response = await axios.post(`${productUrl}/mine`, {user_id: user.id}, {

                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                }
            })
            setProducts(response.data.products);
        } catch (e) {

        }
    }

    const fetchAds = async () => {
        try {
            const response = await axios.get(`${api_url}/adverts?id=${user.id}`, {

                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                }
            })
            console.log(response.data);
            setAds(response.data);
        } catch (e) {

        }
    }

    useEffect(() => {
        fetchProducts();
        fetchAds()
    }, [])

    return (
        <MainLayout>
            <NewAd refresh={fetchAds} show={showModal} products={products} token={token} user={user} close={() => setShowModal(s => !s)}/>
            <Container>
                <div className="flex justify-between mb-3">
                    <h1>Advertisements</h1>
                    <PrimaryButton onClick={() => setShowModal(s => !s)}>New Ad</PrimaryButton>
                </div>

                <div className="space-y-2">
                    {ads?.map((ad, index) =>
                        <div key={index}
                             className="py-6 px-4 gap-2 rounded-lg bg-gray-700/20 hover:bg-gray-700/50 shadow-sm transition-all">
                            <div className="flex items-start gap-4">
                                <img
                                    src={
                                        ad?.picture
                                            ? `${process.env.REACT_APP_BACKEND_URL}/${ad?.picture
                                                ?.replaceAll('"', "")}`
                                            : "/img/shop-icon.png"
                                    }
                                    alt="product-1"
                                    className="object-cover w-24 h-24"
                                />
                                <div className="flex-1 space-y-4">
                                    <div className="flex justify-between items-center">
                                        <div className="font-bold">{ad.title}</div>
                                        <FaGear/>
                                    </div>
                                    <div className="grid grid-cols-3 gap-1">
                                        <div className=""><strong>Price:</strong> K {numberFormat(ad.price)}</div>
                                        <div className=""><strong>Start Date:</strong> {dateFormat(ad.start_date)}</div>
                                        <div className=""><strong>Expiry Date:</strong> {dateFormat(ad.end_date)}</div>

                                        <div className=""><strong>Views:</strong> {ad.view_count}</div>
                                        <div className=""><strong>Min. Age
                                            Target:</strong> {ad.target_min_age || "None"}</div>
                                        <div className=""><strong>Max. Age
                                            Target:</strong> {ad.target_max_age || "None"}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

            </Container>
        </MainLayout>
    );
};
