import Container from "../../../Components/Container";
import PrimaryButton from "../../../Components/PrimaryButton";
import DangerButton from "../../../Components/DangerButton";
import { useEffect, useState } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useProductUpdate } from "../../../Hooks/useProductUpdate";
import UpdateProduct from "../../Products/Modal/UpdateProduct";
import UpdateProductVariants from "../../Products/Modal/UpdateProductVariants";

export default ({ product, user, images, categories, setProduct, token, refresh, brands }) => {
  const [variants, setVariants] = useState(null);
  const [colors, setColors] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [activeSizes, setActiveSizes] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedSizes, setSelectedSizes] = useState([]);

  const handleColor = (event, colors) => {
    setSelectedColors(colors);
    setActiveSizes(variants[colors]);
  };

  const handleSize = (event, sizes) => {
    setSelectedSizes(sizes);
  };

  useEffect(() => {
    const vars = {};
    var newSizes = [];
    setColors([])
    setSizes([])
    setVariants([])

    product?.ProductVariants?.forEach((variant) => {
      if (!vars[variant.color]) {
        vars[variant.color] = [];
        setColors((state) => [...state, variant.color]);
      }
      vars[variant.color].push(variant.size);

      if (!sizes.includes(variant.size)) {
        newSizes.push(variant.size);
      }
    });

    setSizes(
      newSizes.filter(function (item, pos) {
        return newSizes.indexOf(item) == pos;
      })
    );
    setVariants(vars);
  }, [product]);

  // FOR PRODUCT UPDATING
  const [productVariants, setProductVariants] = useState([]);
  const [removedVariants, setRemovedVariants] = useState([]);
  const [productModal, setIsProductModal] = useState(false);
  const [productVariantModal, setIsProductVariantModal] = useState(false);
  const [processing, setProcessing] = useState(false);

  const {
    shops,
    saveProduct: saveUpdateProduct,
    update: updateAction,
    back: backAction,
  } = useProductUpdate({
    user,
    token,
    product,
    productVariants,
    removedVariants,
    setProduct,
    setIsProductModal,
    setIsProductVariantModal,
    setProductVariants,
    setRemovedVariants,
    refresh,
    setProcessing,
  });
  //

  return (
    <>
      {productModal && (
        <UpdateProduct
          show={productModal}
          product={product}
          setProduct={setProduct}
          categories={categories}
          brands={brands}
          close={() => setIsProductModal((s) => !s)}
          setIsProductVariantModal={setIsProductVariantModal}
          shops={shops}
        />
      )}

      {productVariantModal && (
        <UpdateProductVariants
          show={productVariantModal}
          productVariants={productVariants}
          setProductVariants={setProductVariants}
          back={backAction}
          close={() => setIsProductVariantModal((s) => !s)}
          save={saveUpdateProduct}
          processing={processing}
          setRemovedVariants={setRemovedVariants}
        />
      )}
      <Container>
        <div className="text-lg md:text-xl md:flex justify-between font-bold border-b py-2">
          <div>{product?.name}</div>
          <div className="md:ms-2 inline-block md:bg-blue-500 md:py-1 md:px-2 rounded-full text-sm">
            MWK{" "}
            {product?.price?.toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })}
          </div>
        </div>
        <div className="md:grid grid-cols-3 gap-4">
          <div className="w-full shadow mb-2">
            {images.length > 0 && (
              <ImageGallery showPlayButton={false} items={images} />
            )}
          </div>

          <div className="col-span-2">
            <p className="text-justify text-sm text-gray-700 dark:text-gray-300 my-2">
              {product?.description?.length > 0 && (
                <h2 class="mb-2 text-lg font-semibold text-gray-900 dark:text-white">
                  Description:
                </h2>
              )}
              {product?.description}
            </p>
            <p>
              <h2 class="mb-2 text-lg font-semibold text-gray-900 dark:text-white">
                Features:
              </h2>
              <ul class="max-w-md space-y-1 text-gray-500 list-disc list-inside dark:text-gray-400 text-sm">
                <li>Great Sound</li>
                <li>Good Music</li>
                <li>Comfort</li>
              </ul>
            </p>

            {colors.length > 0 && (
              <div className="my-2">
                Color:
                <ToggleButtonGroup
                  color="primary"
                  exclusive
                  value={selectedColors}
                  onChange={handleColor}
                  aria-label="colors"
                >
                  {colors.map((color, index) => (
                    <ToggleButton
                      value={color}
                      aria-label={color}
                      key={index}
                      sx={{ p: 0 }}
                    >
                      <div
                        className={`text-gray-800 dark:text-gray-300 px-3 py-2 rounded ${
                          selectedColors == color //selectedColors.includes(color)
                            ? "bg-blue-300 dark:bg-blue-800"
                            : "bg-slate-200 dark:bg-slate-700 hover:bg-slate-300 dark:hover:bg-slate-600"
                        }`}
                      >
                        {color}
                      </div>
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </div>
            )}

            {sizes.length > 0 && (
              <div className="my-2">
                Sizes:
                <ToggleButtonGroup
                  value={selectedSizes}
                  onChange={handleSize}
                  aria-label="sizes"
                >
                  {sizes.map((size, index) => (
                    <ToggleButton
                      value={size}
                      aria-label={size}
                      disabled={
                        activeSizes ? !activeSizes.includes(size) : false
                      }
                      key={index}
                      sx={{ p: 0 }}
                    >
                      <div
                        className={`text-gray-800 dark:text-gray-300 px-3 py-2 rounded ${
                          activeSizes
                            ? activeSizes.includes(size)
                              ? selectedSizes.includes(size)
                                ? "bg-blue-300 dark:bg-blue-800"
                                : "bg-slate-200 dark:bg-slate-700 hover:bg-slate-300 dark:hover-bg-slate-600"
                              : "bg-gray-500 text-gray-700 opacity-50"
                            : "bg-gray-500 text-gray-700 opacity-50"
                        }`}
                      >
                        {size}
                      </div>
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </div>
            )}

            <div className="my-2 md:flex justify-end">
              <div className="md:flex gap-2">
                <PrimaryButton onClick={() => updateAction(product)}> Edit</PrimaryButton>
                <DangerButton>Delete</DangerButton>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};
