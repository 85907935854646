import Container from "../../../Components/Container";
import PrimaryButton from "../../../Components/PrimaryButton";
import { useEffect, useState } from "react";
import Datatable from "../../../Components/Datatable";
import { Link } from "react-router-dom";
import TrashIcon from "../../../Assets/JSX/TrashIcon";
import EditIcon from "../../../Assets/JSX/EditIcon";
import NewBrand from "./NewBrand";
import { useSelector } from "react-redux";
import {api_url} from "../../../Data/apiRoutes";
import { dateFormat } from "../../../Functions/functions";
import UpdateBrand from "./UpdateBrand";
import axios from "axios";

export default function AdminBrands({ user }) {
  const [showAddBrand, setShowBrand] = useState(false);
  const token = useSelector((state) => state.token);
  const [brands, setBrands] = useState([])
  const [activeBrand, setActiveBrand] = useState({});
  const [showEditBrand, setShowEditBrand] = useState(false);

  const getBrands = async () => {
    const response = await axios.get(`${api_url}/brands`)
    console.log(response.data)
    setBrands(response.data)
  };

  const editCategory = (index) => {
    setActiveBrand(brands[index]);
    setShowEditBrand((s) => !s);
  };

  useEffect(() => {
    getBrands();
  }, []);

  return (
    <Container>
      <NewBrand
        token={token}
        show={showAddBrand}
        close={() => setShowBrand((s) => !s)}
        refresh={getBrands}
      />
      <UpdateBrand
        token={token}
        show={showEditBrand}
        close={() => setShowEditBrand((s) => !s)}
        category={activeBrand}
        refresh={getBrands}
      />
      <div className="mb-3 flex justify-between">
        <div></div>
        <PrimaryButton onClick={() => setShowBrand((s) => !s)}>
          Add Brand
        </PrimaryButton>
      </div>

      {brands ? (
        <Datatable>
          <table
            className={
              "data-table w-full text-sm text-left text-gray-500 dark:text-gray-400 "
            }
          >
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-4 py-2">
                  &nbsp;
                </th>
                <th scope="col" className="px-4 py-2">
                  Shop Name
                </th>
                <th scope="col" className="px-4 py-2">
                  Created At
                </th>
                <th scope="col" className="px-4 py-2">
                  Options
                </th>
              </tr>
            </thead>
            <tbody>
              {brands.map((row, index) => (
                <tr
                  key={`${index}`}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <td className="px-4 py-2 text-gray-900 dark:text-white justify-center">
                    <img
                      src={
                        row?.picture
                          ? `${
                              process.env.REACT_APP_BACKEND_URL
                            }/${row?.picture?.replace(/"/g, "")}`
                          : "/img/image_icon1.png"
                      }
                      width={50}
                      height={50}
                      className="bg-white rounded object-cover"
                    />
                  </td>
                  <td className="px-4 py-2 text-gray-900 dark:text-white underline underline-offset-2">
                    <Link to={`/brands/${row.id}`}>{row.name}</Link>
                  </td>
                  <td className="px-4 py-2 text-gray-900 dark:text-white">
                    {dateFormat(row?.createdAt)}
                  </td>
                  <td className="px-4 py-2 text-gray-900 dark:text-white">
                    <div className="flex justify-center gap-5">
                      <button onClick={() => editCategory(index)}>
                        <EditIcon className="fill-gray-900 dark:fill-gray-200" />
                      </button>
                      <button>
                        <TrashIcon className="fill-gray-900 dark:fill-gray-200" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Datatable>
      ) : (
        <>Loading...</>
      )}
    </Container>
  );
}
